<template>
	<div>
		<edit-consultant :profile="consultant"  @saved="createConsultant()" />
	</div>
</template>

<script>
import api from "@/core/services/ApiService";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import labels from "@/core/config/labels";


import moment from 'moment'
import EditConsultant from '@/components/modals/forms/EditConsultant.vue'
import Swal from "sweetalert2/dist/sweetalert2.js";

export default {
  components: { EditConsultant },
  name: "Consultants",
  
  data(){
	  return {
		  consultant:{
			name:null,
			surname:null,
			email:null,
			phone:null,
			title:null,
			bio:null,
			birthday:null,
			career:null,
			city:null,
			countryIso:'TR',
			education:null,
			gender:null,
			status:null,
			userTypeId:5,
			
		}
    }
  },
 
  methods:{
    moment(date){
      return moment(date);
    },
	
	createConsultant(){
		
		api.post('/User/NewUser',this.consultant).then((res)=>{
			Swal.fire({
				text:'Danışman Oluşturuldu',
				icon:'success',
			}).then(()=>{
				document.location.href='/consultants/'+this.consultant.userTypeId;
			});
		})
	}
    
  },
  mounted(){
	  this.consultant.userTypeId = parseInt(this.$route.query.type);
	  
      setCurrentPageBreadcrumbs("Yeni Danışman Ekleme", ["Danışmanlar"]);    
  }
  
  }
</script>
